export default {
  heading1: ({ children }: any) => `<h1 class="pb-4 text-2xl sm:text-3xl font-bold">${children}</h1>`,
  heading2: ({ children }: any) => `<h2 class="pb-4 text-xl sm:text-2xl font-bold">${children}</h2>`,
  heading3: ({ children }: any) => `<h3 class="pb-3 text-lg sm:text-xl font-bold">${children}</h3>`,
  heading4: ({ children }: any) => `<h4 class="pb-3 text-lg font-bold">${children}</h4>`,
  heading5: ({ children }: any) => `<h5 class="pb-2 text-lg font-semibold">${children}</h5>`,
  heading6: ({ children }: any) => `<h6 class="pb-2 text-lg">${children}</h6>`,
  paragraph: ({ children }: any) => `<p class="pb-2">${children}</p>`,
  listItem: ({ children }: any) => `<li class="pb-2 ml-2">${children}</li>`,
  oListItem: ({ children }: any) => `<li class="pb-2 ml-2">${children}</li>`,
  list: ({ children }: any) => `<ul class="list-disc list-outside pl-4 py-2">${children}</ul>`,
  oList: ({ children }: any) => `<ol class="list-decimal list-outside pl-4 py-2">${children}</ol>`
}
