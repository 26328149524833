import { default as indexAu4pBlxyvDMeta } from "/workspace/app/pages/appointment/cancel/index.vue?macro=true";
import { default as _91step_93uiCiTMZZAjMeta } from "/workspace/app/pages/appointment/new/[step].vue?macro=true";
import { default as _91uuid_93uz0f13npcNMeta } from "/workspace/app/pages/appointment/success/[uuid].vue?macro=true";
import { default as welcomeWEim0zPrZ1Meta } from "/workspace/app/pages/appointment/welcome.vue?macro=true";
import { default as index6n3ZPBUsMaMeta } from "/workspace/app/pages/index.vue?macro=true";
export default [
  {
    name: "appointment-cancel",
    path: "/appointment/cancel",
    component: () => import("/workspace/app/pages/appointment/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "appointment-new-step",
    path: "/appointment/new/:step()",
    meta: _91step_93uiCiTMZZAjMeta || {},
    component: () => import("/workspace/app/pages/appointment/new/[step].vue").then(m => m.default || m)
  },
  {
    name: "appointment-success-uuid",
    path: "/appointment/success/:uuid()",
    meta: _91uuid_93uz0f13npcNMeta || {},
    component: () => import("/workspace/app/pages/appointment/success/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "appointment-welcome",
    path: "/appointment/welcome",
    meta: welcomeWEim0zPrZ1Meta || {},
    component: () => import("/workspace/app/pages/appointment/welcome.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/app/pages/index.vue").then(m => m.default || m)
  }
]