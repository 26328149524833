export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return

  if (
    to.query?.serviceUuid &&
    (to.name !== 'appointment-new-step' || (to.name === 'appointment-new-step' && to.params?.step !== '3'))
  ) {
    return navigateTo('/appointment/new/3?serviceUuid=' + to.query?.serviceUuid)
  }

  if (
    to.query?.businessUnitUuid &&
    (to.name !== 'appointment-new-step' || (to.name === 'appointment-new-step' && to.params?.step !== '2'))
  ) {
    return navigateTo('/appointment/new/2?businessUnitUuid=' + to.query?.businessUnitUuid)
  }
})
