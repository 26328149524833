export type Notification = {
  type: 'success' | 'error' | 'info' | 'warning' | null
  message: string | null
  title: string | null
}

export const useNotification = () => {
  const notification = useState<Notification>('useNotification', () => {
    return {
      type: null,
      message: null,
      title: null
    }
  })

  const sendNotification = (
    message: string,
    type: 'success' | 'error' | 'info' | 'warning' = 'info',
    title: string = ''
  ) => {
    if (
      notification.value.title === title &&
      notification.value.message === message &&
      notification.value.type === type
    ) {
      return
    }

    if (title === '') {
      if (type === 'info') title = 'Information'
      if (type === 'success') title = 'Erfolgreich'
      if (type === 'warning') title = 'Warnung'
      if (type === 'error') title = 'Fehler'
    }

    notification.value = {
      title,
      message,
      type
    }
  }

  const sendInfo = (message: string, title: string = '') => {
    if (notification.value.title === title && notification.value.message === message) return
    if (title === '') title = 'Information'

    notification.value = {
      title,
      message,
      type: 'info'
    }
  }

  const sendSuccess = (message: string, title: string = '') => {
    if (notification.value.title === title && notification.value.message === message) return
    if (title === '') title = 'Erfolgreich'

    notification.value = {
      title,
      message,
      type: 'success'
    }
  }

  const sendWarning = (message: string, title: string = '') => {
    if (notification.value.title === title && notification.value.message === message) return
    if (title === '') title = 'Warnung'

    notification.value = {
      title,
      message,
      type: 'warning'
    }
  }

  const sendError = (message: string, title: string = '') => {
    if (notification.value.title === title && notification.value.message === message) return
    if (title === '') title = 'Fehler'

    notification.value = {
      title,
      message,
      type: 'error'
    }
  }

  const reset = () => {
    notification.value = {
      title: null,
      message: null,
      type: null
    }
  }

  return {
    notification,
    sendNotification,
    sendInfo,
    sendSuccess,
    sendWarning,
    sendError,
    reset
  }
}
