import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry }
  } = useRuntimeConfig()

  if (sentry.disable || sentry.disable === 'true' || !sentry.dsn) {
    return
  }

  Sentry.init({
    ignoreErrors: ['Non-Error promise rejection captured'],
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  })
})
