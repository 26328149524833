export const useStep: useStepReturn = () => {
  const DEFAULT_STEPS = [
    {
      description: 'Wähle einen unserer Standorte aus.',
      hint: 'Bitte wähle Deinen bevorzugten Standort aus.',
      href: '/appointment/new/1',
      id: '01',
      name: 'Standort',
      status: 'current',
      title: 'Wo dürfen wir Dich begrüßen?'
    },
    {
      description: 'Wählen einen Services aus. Überzeuge Dich selbst.',
      hint: 'Bitte wähle Deinen passenden Service aus.',
      href: '/appointment/new/2',
      id: '02',
      name: 'Service',
      status: 'upcoming',
      title: 'Was können wir Dir Gutes tun?'
    },
    {
      description: 'Wählen das für Dich passende Datum und beste Uhrzeit aus.',
      hint: 'Bitte wähle Datum und Uhrzeit aus.',
      href: '/appointment/new/3',
      id: '03',
      name: 'Datum',
      status: 'upcoming',
      title: 'Wann würde es Dir am besten passen?'
    },
    {
      description: 'Nenne uns noch ein paar Infos zu dir und teile uns bei Bedarf noch ein Kommentar.',
      hint: 'Bitte teile uns noch ein paar Informationen von Dir mit.',
      href: '/appointment/new/4',
      id: '04',
      name: 'Kontakt',
      status: 'upcoming',
      title: 'Wie können wir Dich erreichen?'
    }
  ] as Array<Step>

  const state = useState<UseStepState>('useStep', () => ({
    steps: useCloneDeep(DEFAULT_STEPS)
  }))

  const setStep = (step: number) => {
    const stepIndex = step - 1
    if (stepIndex < 0) return
    if (state.value.steps.length <= stepIndex) return
    useEach(state.value.steps, (step: Step, index: number) => {
      if (index < stepIndex) step.status = 'complete'
      if (index === stepIndex) step.status = 'current'
      if (index > stepIndex) step.status = 'upcoming'
    })
  }

  const nextStep = () => {
    const currentIndex = useFindIndex(state.value.steps, { status: 'current' })
    if (currentIndex === -1) return
    if (state.value.steps.length - 1 <= currentIndex) {
      state.value.steps[currentIndex].status = 'complete'
      return
    }
    state.value.steps[currentIndex].status = 'complete'
    state.value.steps[currentIndex + 1].status = 'current'
  }

  const previousStep = () => {
    const currentIndex = useFindIndex(state.value.steps, { status: 'current' })
    if (currentIndex === -1 || currentIndex === 0) return
    state.value.steps[currentIndex].status = 'upcoming'
    state.value.steps[currentIndex - 1].status = 'current'
  }

  const reset = () => {
    state.value.steps = useCloneDeep(DEFAULT_STEPS)
  }

  const allStepsComplete = computed(() => state.value.steps.every((step: Step) => step.status === 'complete'))

  const currentStepIndex = computed(() => {
    const current = useFindIndex(state.value.steps, { status: 'current' })
    return current === -1 ? state.value.steps.length : current + 1
  })

  const currentStep = computed(() => useFind(state.value.steps, { status: 'current' }))

  const totalSteps = computed(() => state.value.steps.length)

  return {
    setStep,
    nextStep,
    previousStep,
    reset,
    allStepsComplete,
    currentStepIndex,
    currentStep,
    totalSteps,
    ...toRefs(state.value)
  }
}
