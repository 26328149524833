import validate from "/workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import query_45params_45global from "/workspace/app/middleware/queryParams.global.ts";
import manifest_45route_45rule from "/workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  query_45params_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "signed-link": () => import("/workspace/app/middleware/signedLink.ts"),
  step: () => import("/workspace/app/middleware/step.ts")
}