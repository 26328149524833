import { addMethod, setLocale, string } from 'yup'

export default defineNuxtPlugin(() => {
  setLocale(yupLocaleDe)

  // Check for special german characters (not allowed in emails for example)
  addMethod(string, 'noGermanSpecialChars', function (errorMessage = '') {
    const GERMAN_SPECIAL_CHARACTERS = ['ä', 'Ä', 'ü', 'Ü', 'ö', 'Ö', 'ß']
    const errMsg =
      typeof errorMessage === 'string' && errorMessage ? errorMessage : `\${path} hat Sonderzeichen: ä, ö, ü oder ß`

    return this.test('noGermanSpecialChars', errMsg, (value) => {
      if (isEmpty(value) || value === undefined) return true
      return !GERMAN_SPECIAL_CHARACTERS.some((specialChar) => value.includes(specialChar))
    })
  })
})
