import { useScript } from 'unhead'

// script has to be called here in order to "catch" the "UC_UI_INITIALIZED" event
export const useUserCentrics = () => {
  const gtm = useGtm()
  const config = useRuntimeConfig()

  const setup = async () => {
    useScript(
      {
        id: 'usercentrics-cmp',
        'data-settings-id': config.public.usercentrics.settingsId,
        'data-language': 'de',
        src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
        async: true
      },
      {
        beforeInit: () => {
          if (import.meta.client) {
            window.addEventListener('UC_UI_CMP_EVENT', onUcUiEvent)
            window.addEventListener('UC_UI_INITIALIZED', onUcUiInit)
          }
        }
      }
    )
  }

  const onUcUiEvent = (event: CustomEvent) => {
    handleRequiresReload(event)
  }

  const onUcUiInit = (_event: CustomEvent) => {
    gtm?.enable(true)
  }

  const handleRequiresReload = (event: CustomEvent) => {
    const actionableEvents = ['DENY_ALL', 'SAVE']
    const eventType = event?.detail?.type
    if (!actionableEvents.includes(eventType)) return
    window.location.reload()
  }

  return {
    setup
  }
}
