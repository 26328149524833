
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/de'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import minMax from 'dayjs/plugin/minMax'
import duration from 'dayjs/plugin/duration'


dayjs.extend(updateLocale)

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(minMax)
dayjs.extend(duration)

dayjs.tz.setDefault('Europe/Berlin')

// defaultLocale: "de"


dayjs.updateLocale("de")
dayjs.locale('de')


export default dayjs
